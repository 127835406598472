<template>
  <div class="main">
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <common-top
      title="运动健康"
      @search="searchUserStatus = true"
      :isShowSearch="isUserPm"
    ></common-top>

    <loading-cmp v-if="showLoad"></loading-cmp>
    <div class="scrollDiv" v-show="isUserPm" style="margin-top: 10px">
      <div style="box-shadow: 0 1px 6px rgb(0 0 0 / 6%); padding-bottom: 10px">
        <date-and-desc-com
          :startDate="startDate"
          :endDate="endDate"
          @chooseDate="chooseDate"
        ></date-and-desc-com>
        <div
          style="
            display: flex;
            padding: 0px 10px;
            font-size: 14px;
            margin-bottom: 8px;
          "
        >
          <div style="flex: 1; text-align: left" v-if="wxUser">
            <span style="color: #aaa">统计人员: &nbsp;</span>
            <span style="color: #0061b0">{{ wxUser.name }}</span>
          </div>
        </div>
      </div>

      <div v-show="searchUserStatus">
        <user-list
          @searchBack="searchUserStatus = false"
          @chooseItem="chooseItem"
        >
        </user-list>
      </div>
      <div v-show="!searchUserStatus">
        <div style="box-shadow: 0 1px 6px rgb(0 0 0 / 6%)">
          <div
            v-if="detailInfo['总步数']"
            style="
              text-align: right;
              padding: 10px 20px;
              box-shadow: 0 1px 6px rgb(0 0 0 / 6%);
            "
          >
            <svg
              style="display: inline-block"
              t="1648469322730"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="24311"
              width="20"
              height="20"
            >
              <path
                d="M810.666667 1002.666667H234.666667c-70.570667 0-128-57.429333-128-128 0-27.477333 9.045333-54.741333 25.557333-76.757334L346.666667 512 132.266667 226.176A128.170667 128.170667 0 0 1 234.666667 21.333333H810.666667c58.794667 0 106.666667 47.829333 106.666666 106.666667v128a64 64 0 1 1-128 0V149.333333h-554.666666l243.2 324.266667c17.066667 22.741333 17.066667 54.101333 0 76.842667l-243.2 324.266666h554.666666V768a64 64 0 1 1 128 0v128c0 58.837333-47.872 106.666667-106.666666 106.666667z"
                fill="#0061b0"
                p-id="24312"
              ></path>
              <path
                d="M341.290667 1002.666667A64.042667 64.042667 0 0 1 290.133333 900.224l291.2-388.266667L290.133333 123.733333a64 64 0 0 1 102.4-76.8l320 426.666667c17.066667 22.784 17.066667 54.058667 0 76.842667l-320 426.666666a64 64 0 0 1-51.242666 25.557334z"
                fill="#0061b0"
                p-id="24313"
              ></path>
            </svg>
            <span style="color: #0061b0">
              总步数<span style="font-weight: bold">{{
                detailInfo["总步数"]
              }}</span>
            </span>
          </div>
        </div>

        <div style="margin-top: 10px">
          <pie-chart
            :chartSytle="`width:98vw; height: 300px`"
            ref="userRun"
            id="userRunId"
            :option="optionUserRun"
          ></pie-chart>
        </div>
      </div>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";

import { wxConPost, getUserPm } from "../../api/httpApi";
import wxLoginApi from "../../api/wxLoginApi";
import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import deptTree from "../../components/deptTree.vue";
import userList from "../../components/userList.vue";
import dateAndDescCom from "../../components/dateAndDescCom.vue";
import pieChart from "../../components/pieChart.vue";

export default {
  name: "personJifen",
  components: {
    loadingCmp,
    noPassCom,
    deptTree,
    diaLogliu,
    userList,
    commonTop,
    dateAndDescCom,
    pieChart,
  },

  data() {
    return {
      isUserPm: true, // 是否有权限
      showLoad: true, // 显示加载框

      searchUserStatus: false, // 显示用户列表 | 显示编辑用户
      toSearchData: false, // 用户组件是否查询

      detailInfo: {
        总步数: 0,
      }, // 积分详情
      userRunAll: 0,

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      startDate: "",
      endDate: "",
      wxUser: {},
      dataList: [],
      chartData: [],
      chartXAxis: [],
    };
  },

  methods: {
    chooseDate(emitData) {
      this.startDate = emitData.startDate;
      this.endDate = emitData.endDate;
      this.getUserRun();
    },
    chooseItem(wxUser) {
      this.wxUser = wxUser;
      this.searchUserStatus = false;
      this.getUserRun();
    },

    back() {
      this.$router.push({ path: "/statsmain" });
    },
    async getUserRun() {
      try {
        this.showLoad = true;
        const _query = {
          userid: this.wxUser.userid,
          startDate: this.startDate,
          endDate: this.endDate,
        };
        const ret = await wxConPost(
          "/services/gttt-wxstats-mgt/activity/userWxRun",
          _query
        );

        this.dataList = _.get(ret, "data", []);
        this.dealData();
        this.showLoad = false;
      } catch (e) {
        this.$Notice.warning({
          title: "请稍后重试",
        });
        this.showLoad = false;
      }
    },

    dealData() {
      this.chartXAxis = _.map(this.dataList, "date");

      this.chartData = [];
      this.detailInfo["总步数"] = 0;
      // console.log("-----", this.detailInfo, this.chartData);
      for (const iterator of this.dataList) {
        this.chartData.push(iterator["wxRun"]);
        this.detailInfo["总步数"] += iterator["wxRun"];
      }

      this.$nextTick(() => {
        this.$refs.userRun.refreshData();
      });
    },
  },
  computed: {
    optionUserRun() {
      let option = {
        title: {
          left: "center",
          text: "运动曲线",
        },
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
          },
        },
        calculable: true,

        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.chartXAxis,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的
            type: "line",

            itemStyle: {
              normal: {
                // color: "#119754",
              },
            },
            data: this.chartData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      };
      return option;
    },
  },
  async created() {
    this.startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
    this.showLoad = false;

    const userPm = getUserPm();
    if (userPm) {
      this.isUserPm = true;
      wxLoginApi.onReady(async () => {
        this.wxUser = wxLoginApi.getUser();
        this.getUserRun();
      });
    } else {
      this.isUserPm = false;
    }
  },
  watch: {},
};
</script>

<style scoped>
.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}
.desc p {
  margin-top: 4px;
  color: #666;
}
</style>
